import React from "react";
import "./App.css";
import Menu from "./Menu";
import Footer from "./Footer";
import Home from "../Home";
import Contact from "../Contact";
import About from "../About";
import Career from "../Career";


function App() {
  return (
    <>
      <div>
        <Menu />
      </div>
      <div>
        <Home />
      </div>
      <div className="bg-color-custom">
        <hr style={{ marginTop: "25px", marginBottom: "25px" }} />
        <div id="focusable-about">
          <About />
        </div>
      </div>
      <hr style={{ marginTop: "25px", marginBottom: "25px" }} />
      <div id="focusable-career" className="mb-5">
        <Career />
      </div>
      <hr style={{ marginBottom: "25px" }} />
      <div id="focusable-contact" style={{marginBottom: "80px"}}>
        <Contact />
      </div>
      <div className="footer">
        <Footer />
      </div>
    </>
  );
}

export default App;
