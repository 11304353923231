import React from "react";
import "./styles.css";

function Footer() {
  return (
      <footer className="footer py-2 center-footer d-flex footer-box">
        <div className="col-md-6">
          <p>UAB "Kevia" J. Basanavičiaus 54, LT-65210 Varėna, Lithuania</p>
        </div>
        <div className=" col-md-3">
          <p className="text-center">Mob.: +37061139669</p>
        </div>
        <div className="col-md-3">
          <p> Email: info@kevia.lt</p>
        </div>
      </footer>
  );
}

export default Footer;
