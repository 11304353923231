import React from "react";
import image2 from "../Assets/road.jpg";
import "./styles.css";
//import { useTranslation } from "react-i18next";
function About() {

  //const { t } = useTranslation('home');

  return (
    <div className="container">
      <div className="row">
        {/*<h1 className="mb-4">{t`About kevia`}</h1>*/}
        <div className="col-md-7 m-auto">
          <div className="about-text-container-width text-center">
            <p className="">
            Kevia, established in 2015, stands as a prominent medium-sized, family-owned enterprise specializing in international transportation services. Over the years, the company has navigated a path of continuous growth and development, garnering a well-deserved reputation as a trusted and valued partner within the domain of premium-quality material distribution and unwavering services. Kevia's operational footprint extends across the expanse of Western Europe, serving as a testament to their commitment to excellence in the realm of international logistics.
            </p>
          </div>
        </div>
        <div className="col-md-5">
          <div className="image-position">
            <img
              src={image2}
              className="about-text-container-width center"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
