import React from "react";
import "./styles.css";

function Contact() {
  return (
    <div className="container">
      <div className="w3-container w3-padding-64 contact" id="contact">
        <h1>Contact</h1>
        <br />
        <div className="col-md-8 mx-auto">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="text-center">
                <p>UAB "Kevia"</p>
                <p>J. Basanavičiaus 54, LT-65210 Varėna, Lithuania</p>
                <p>Company code: 304093026</p>
                <p>Vat code: LT100009662111</p>
              </div>
              
            </div>
            <div className="col-md-6 col-sm-12 second-box">
              <div className="text-center">
                <p>Mob.: +37061139669</p>
                <p>Email: info@kevia.lt</p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
