import logo from "../../Assets/kevia.png";
import { BrowserRouter as Router } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import "./styles.css";

import { HashLink } from "react-router-hash-link";

function Menu() {
  return (
    <div className="menu">
      <Router>
        <Navbar bg="light" expand="lg">
          <Container>
            <img src={logo} alt="" className="logo"></img>

            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse className="justify-content-end" id="navbarScroll">
              <Nav>
                <Nav.Link>
                  <HashLink smooth to={"/about#focusable-about"}>
                    About
                  </HashLink>
                </Nav.Link>
                <Nav.Link>
                  <HashLink smooth to={"/contact#focusable-career"}>
                    Career
                  </HashLink>
                </Nav.Link>
                <Nav.Link>
                  <HashLink smooth to={"/contact#focusable-contact"}>
                    Contact
                  </HashLink>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Router>
    </div>
  );
}

export default Menu;
