import "./styles.css";
import image2 from "../Assets/truck.jpg";

function Home() {
  return (
    <div>
      <img src={image2} className="image" />
      <div className="col-md-4 col-sm-4 text-container">
        <h3 className="text-white text-custom font-weight-bold">
          Lets travel around Europe together
        </h3>
      </div>
    </div>
  );
}

export default Home;
