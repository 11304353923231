import React from "react";
import Modal from "react-modal";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

function Career() {
  const [modalIsOpen, setIsOpen] = React.useState<boolean>(false);
  const [jobDescription, setJobDescription] = React.useState<string>('');

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className="container">
      <div className="w3-container w3-padding-64" id="career">
        <h1>Career</h1>
        <br />
        <div className="col-md-8 mx-auto">
          <div className="row">
            <div className="col-md-6 col-sm-12 my-1">
              <div
                  className="card border-secondary mb-3"
                  style={{ maxWidth: "18rem" }}
              >
                <div className="card" style={{ maxWidth: "18rem"}}>
                  <div className="card-body">
                    <h5 className="card-title">Drivers</h5>
                    <p className="card-text">We are always looking for experienced drivers who are ready to go an extra mile and deliver exceptional service in return for a friendly and supportive work environment.</p>
                    {/*<a onClick={openModal} className="card-link">read more</a>*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 my-1">
              <div
                  className="card border-secondary mb-3"
                  style={{ maxWidth: "18rem" }}
              >
                <div className="card" style={{ maxWidth: "18rem"}}>
                  <div className="card-body">
                    <h5 className="card-title">Managers</h5>
                    <p className="card-text">If you are passionate about transport, speak several languages and want to become a valuable team member, please contact us. We might find a place for you!</p>
                    {/*<a onClick={openModal} className="card-link">read more</a>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
       
      >
        <a className="card-link modal-close-link" onClick={closeModal}>
          close
        </a>
      </Modal>
    </div>
  );
}

export default Career;
