import React from "react";
import ReactDOM from "react-dom";
import App from "./App/App";
import "bootstrap/dist/css/bootstrap.min.css";
import TagManager from "react-gtm-module";
//import './translations/index'

const tagManagerArgs = {
    gtmId: 'GTM-N7Q28T27'
}

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById("root"));
